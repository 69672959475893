
import { defineComponent } from 'vue'
import axios from 'axios'
import {Param as ParamType, Image as ImageType} from '../types/Post'

export default defineComponent({
  name: 'FieldView',
  data: () => {
    return {
      uploadState: {
        disabled: false,
        animate: ''
      },
      fetchState: {
        disabled: false,
        animate: ''
      },
      locationState: {
        disabled: false,
        animate: ''
      },
      text: '',
      lat: '',
      lon: '',
      location: '---',
      images: [] as ImageType[],
      locations: ['---'],
      allowState: {
        loc: true
      }
    }
  },
  created: async function () {
    this.getPosition()
    await this.fetch()
  },
  methods: {
    onFileChange(e: any) {
      this.images = []
      let idx = 0
      for (const file of e.target.files) {
        this.images.push({idx: idx, blob: file, url: URL.createObjectURL(file)})
        idx++
      }

    (this.$refs.fileInput as any).reset()
      
    },
    getPosition() {
      this.locationState.animate = 'fade'
      navigator.geolocation.getCurrentPosition((pos) => {
        this.lat = pos.coords.latitude.toString()
        this.lon = pos.coords.longitude.toString()
        this.locationState.animate = ''
        this.getLocation()
      }, function(err) {
        console.log(err)
      }, {
        enableHighAccuracy: true
      });
    },
    async getLocation() {
      if (this.lat == '' && this.lon == '') return;

      const result = await axios.get(`${process.env.VUE_APP_ARTICLE_API}/nearby/${this.lat}/${this.lon}`)
      this.locations = result.data.locations
      this.locations.unshift('---')
    },
    async upload() {
      this.uploadState.animate = 'fade'
      this.uploadState.disabled = true

      let image_urls = []
      for (const image of this.images) {
        const formdata = new FormData();
        formdata.append('image', image.blob)

        let image_result = await axios.post(process.env.VUE_APP_UPLOAD_API + '/upload', formdata, {headers: {'content-type': 'multipart/form-data'}});

        image_urls.push(image_result.data.id);
      }

      const postData = {
        text: this.text,
        lat: this.lat,
        lon: this.lon,
        location: this.location,
        images: image_urls
      } as ParamType

      const axiosResponse = await axios.post(process.env.VUE_APP_ARTICLE_API + '/post', postData)
      const { data } = axiosResponse;

      (this.$parent as any).posts.unshift(data.id)
      
      this.uploadState.animate = ''
      this.uploadState.disabled = false

      this.text = ''
      this.location = '---'
      this.locations = ['---']
      this.images = []

      this.getPosition();
    },
    async fetch() {
      this.fetchState.animate = 'spin';
      this.fetchState.disabled = true;

      const result = await axios.get(process.env.VUE_APP_ARTICLE_API + '/');

      (this.$parent as any).posts = result.data.data;
      
      this.fetchState.animate = '';
      this.fetchState.disabled = false;
    },
    removeImage(idx: number) {
      this.images = this.images.filter(image => image.idx != idx)
    },
    changeLocState() {
      this.allowState.loc = !this.allowState.loc

      if (this.allowState.loc) {
        this.getPosition();
      } else {
        this.lat = ''
        this.lon = ''
      }
    }
  }
})
